import { useRouter } from "next/dist/client/router";
import { useState } from "react";
import cookieCutter from 'cookie-cutter'

import { apiPath } from "constants/api"

export default function Auth() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const router  = useRouter()

    async function signIn() {
        const formdata = new FormData();
        formdata.append("email", email);
        formdata.append("password", password);

        console.log("signing in")

        const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow"
        };

        try {
            const response = await fetch(
                `${apiPath}/auth/sign_in/`,
                requestOptions
            );
            const headers = {
                expiry: response.headers.get("expiry"),
                uid: response.headers.get("uid"),
                client: response.headers.get("client"),
                accessToken: response.headers.get("access-token"),
            };
            cookieCutter.set("@vrachtscan:user", JSON.stringify(headers))

            router.push("/projects")
        } catch (error) {
            console.log(error)
        }
    }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="/assets/vrachtscan_avatar.svg"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Inloggen</h2>
          </div>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Emailadres
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Emailadres"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Wachtwoord
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Wachtwoord"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      signIn()
                    }
                  }}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={() => signIn()}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Inloggen
              </button>
            </div>
        </div>
      </div>
    </>
  )
}
